<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.download"></custom-page-title>

                <h2>Szerződésminták</h2>

                <p>Az egyszerűsített cégeljáráshoz szükséges szerződésmintákat
                    a cégbejegyzési eljárás és a cégnyilvántartás egyes kérdéseiről szóló 21/2006.
                    (V. 18.) IM rendelet mellékletei tartalmazzák, amelyek szerkeszthető
                    formátumban a <a
                        href="https://ceginformaciosszolgalat.kormany.hu/szerzodesmintak" target="_blank">Céginformációs
                        Szolgálat</a> oldalán érhetőek el.</p>

                <h2>Cégbejegyzési, változásbejegyzési nyomtatványok elérhetősége</h2>


                <div>Nyomtatványkitöltő programot a következő cégek forgalmaznak:
                    <ul>
                        <li><a
                            href="https://shop.wolterskluwer.hu/" target="_blank">Wolters Kluwer Kft.</a></li>

                        <li><a
                            href="http://www.cegirnok.hu/" target="_blank">eGov Kft.</a></li>

                        <li><a
                            href="https://jogkodex.hu/" target="_blank">ORAC Kiadó Kft.</a></li>

                        <li><a
                            href="http://www.onlinecegeljaras.hu/" target="_blank">Net-Solicitor
                            Kft.</a></li>

                        <li><a
                            href="http://www.vibastile.hu/" target="_blank">ViBaStile
                            Kft.</a></li>
                    </ul>

                </div>
                <p></p>
                <p>Az elektronikus cégbejegyzési eljárás és cégnyilvántartás
                    egyes kérdéseiről szóló 24/2006. (V. 18.) IM rendelet 5. §-a szerint
                    elektronikus cégeljárás esetén a kérelmet elektronikus nyomtatványon kell
                    előterjeszteni.</p>

                <p>Felhívás szoftverfejlesztőknek!<br>
                    Az elektronikus cégeljáráshoz használható
                    <router-link to="/help/company-form">elektronikus
                        nyomtatványokra vonatkozó feltételek
                    </router-link>
                    .
                </p>

                <h2>Aláírás létrehozó és ellenőrző program</h2>

                <p>A fokozott biztonságú vagy minősített elektronikus
                    aláírással ellátott elektronikus okiratok, igazolások létrehozásához,
                    aláírásához és időbélyegzéséhez, valamint az aláírással ellátott okiratok
                    megismeréséhez aláírás létrehozó és ellenőrző program szükséges, amely megfelel
                    az Igazságügyi Minisztérium által közzétett műszaki követelményeknek.</p>

                <p>A cégeljárásban jelenleg alkalmazható aláíró szoftverek:</p>

                <p><a href="https://www.e-szigno.hu/e-Szigno_bemutato"
                      target="_blank">e-Szignó</a> (gyártó: Microsec zrt.)</p>

                <p><a href="http://www.netlock.hu/mokka" target="_blank">MOKKA</a> (gyártó:
                    Netlock Kft.)</p>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpDownload extends Vue {
}
</script>

<style scoped>

</style>