import { render, staticRenderFns } from "./HelpDownload.vue?vue&type=template&id=507c7f40&scoped=true"
import script from "./HelpDownload.vue?vue&type=script&lang=js"
export * from "./HelpDownload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507c7f40",
  null
  
)

export default component.exports